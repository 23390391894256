import React, { useEffect, useState } from 'react'
import style from './collectionReportList.module.css'
import machineImage1 from '../../assests/collectiontreport.png'
import machineImage2 from '../../assests/collectiontreport.png'
import machineImage3 from '../../assests/collectiontreport.png'
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { baseurl } from '../../config/Base_url'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const CollectionReportList = () => {
  const storedToken = localStorage.getItem('authToken');
  const userDetail = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState();
  const [n, setN] = useState(9);
  const [collectionReports, setCollectionReports] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // New state for selected image

  useEffect(() => {
    const fetchCollectionReports = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/user/collectionall/report/${userDetail._id}`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`, // Set the token in the Authorization header
            'Content-Type': 'application/json'
          }
        });

        if (response.data.success) {
          setCollectionReports(response.data.allCollectionReports);
        }
      } catch (error) {
        console.error('Error fetching collection reports:', error);
      }
    };

    fetchCollectionReports();
  }, []);

  const machines = collectionReports.flatMap(report => report.machines || []); // Flatten all machines into a single array

  const totalProfit = machines.reduce((acc, machine) =>
    acc +
    (machine.inNumbers.current + machine.inNumbers.previous) -
    (machine.outNumbers.current + machine.outNumbers.previous), 0);

  const paid = machines.reduce((acc, machine) =>
    acc + machine.outNumbers.current + machine.outNumbers.previous, 0);

  const due = totalProfit - paid;

  const fiftyPercent = totalProfit / 2;


  const perPageOptions = [
    { value: 9, label: '9' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
  ];

  // useEffect(() => {
  //     setFilterData(
  //         locations?.filter((item, index) => {
  //             return (index >= page * n) & (index < (page + 1) * n);
  //         })
  //     );
  // }, [page, n, locations]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  const handlePerPageChange = (event) => {
    setN(Number(event.target.value));
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedAccordionData, setSelectedAccordionData] = useState(null);
  const machinesSelected = selectedAccordionData?.machines || []; // Default to an empty array if no machines

  // Calculate total profit
  const totalProfitSelected = machinesSelected.reduce((acc, machine) =>
    acc +
    (machine.inNumbers.current + machine.inNumbers.previous) -
    (machine.outNumbers.current + machine.outNumbers.previous), 0);

  // Calculate paid
  const paidSelected = machinesSelected.reduce((acc, machine) =>
    acc + machine.outNumbers.current + machine.outNumbers.previous, 0);

  // Calculate due
  const dueSelected = totalProfitSelected - paidSelected;

  // Calculate 50% of total profit
  const fiftyPercentSelected = totalProfitSelected / 2;

  const handleAccordionItemClick = (data) => {
    setSelectedAccordionData(data);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePrint = () => {
    const content = document.getElementById('modalContent');

    if (content) {
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(`
        <link rel="stylesheet" type="text/css" href="./collectionReportList.module.css">
        <div class="printStyles">
          ${content.innerHTML}
        </div>
      `);
      pri.document.close();
      pri.focus();
      pri.print();
    }
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const openFullscreen = (image) => {
    setSelectedImage(image); // Store the selected image in state
    setShowImageModal(true);
  };

  const machineImages = [machineImage1];

  const MachineImagesSlider = ({ images }) => {
    return (
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`machine-${index}`} width="100%" />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: '0px', width: '0px', position: 'absolute' }}
      ></iframe>
      {
        collectionReports?.map((item, index) => {
          return (
            <div className="accordion" id="accordionExample" key={index}>
              <div className="accordion-item border-0">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button px-5 py-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <div className={`${style.listrow} row rounded-2 w-100 align-items-center`}>
                      <div className="col-2 p-0">
                        <div className='d-flex align-items-center'>
                          <p className='fw-bold mb-0 '>{new Date(item.createdAt).toLocaleDateString()}</p>
                        </div>
                      </div>
                      <div className="col-2  p-0">
                        <div>
                          <p className='fw-bold mb-0 '>{item.employee?.firstname} {item.employee?.lastname}</p>
                        </div>
                      </div>
                      <div className="col-2  p-0">
                        <div>
                          <p className='fw-bold mb-0 '>{item.location?.locationname}</p>
                        </div>
                      </div>
                      <div className="col-1  p-0">
                        <div>
                          <p className='fw-bold mb-0 ms-2'>{item.machines?.length}</p>
                        </div>
                      </div>
                      <div className="col-2  p-0">
                        <div>
                          <p className='fw-bold mb-0 ms-3'>0</p>
                        </div>
                      </div>
                      <div className="col-1 p-0">
                        <div>
                          <p className='fw-bold mb-0 ms-3'>0</p>
                        </div>
                      </div>
                      <div className="col-2  d-flex align-items-center justify-content-between">
                        <div>
                          <p className='fw-bold mb-0 ms-3'>{item.location.address}</p>
                        </div>
                      </div>
                    </div>

                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="row ">
                      {item.machines.map((machine, index) => {
                        return (
                          <div className={`col-4 `} key={index}>
                            <div className='px-3'>
                              <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                                <div className="col-6">
                                  <p className=''>Machine- {machine.machineNumber}-{machine.serialNumber}</p>
                                </div>
                                <div className="col-2">
                                  <p className=' '>Current</p>
                                </div>
                                <div className="col-2">
                                  <p className=''>Privious</p>
                                </div>
                                <div className="col-2">
                                  <p className=''>Total</p>
                                </div>
                              </div>
                              <div className="row p-3 pb-0">
                                <div className="col-4">
                                  <MachineImagesSlider images={machine.image} />
                                </div>
                                {/* {machine.image.map((machineImage, index) => ( */}
                                {/* <div key={index} className="col-4">
                                  <div className="image-container" onClick={() => openFullscreen(machine.image[0])}>
                                    <img src={machine.image[0]} alt='machine' width="50%" />
                                  </div>
                                </div> */}
                                {/* ))} */}


                                <div className="col-2 ps-2">
                                  <p className=''>In</p>
                                  <p className=''>Out</p>
                                </div>
                                <div className="col-2">
                                  <p className=' '>${machine.inNumbers.current}</p>
                                  <p className=' '>${machine.outNumbers.current}</p>
                                </div>
                                <div className="col-2">
                                  <p className=' '>${machine.inNumbers.previous}</p>
                                  <p className=' '>${machine.outNumbers.previous}</p>
                                </div>
                                <div className="col-2">
                                  <p>${machine.inNumbers.current + machine.inNumbers.previous}</p>
                                  <p>${machine.outNumbers.current + machine.outNumbers.previous}</p>
                                </div>
                              </div>
                              <div className={`row p-3 rounded-bottom pb-0`}>
                                <div className="col-9 text-end">
                                  Profit:
                                </div>
                                <div className="col-3">
                                  ${
                                    (machine.inNumbers.current + machine.inNumbers.previous) -
                                    (machine.outNumbers.current + machine.outNumbers.previous)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered dialogClassName={`${style.printmodal}`}>
                        <Modal.Body >
                          <img src={selectedImage} alt='machine' className={`${style.fullscreenimage}`} />
                        </Modal.Body>
                      </Modal>
                    </div>
                    <div className="row mt-3">
                      <div className="col-4 text-end">
                        Total Profit- ${totalProfit}
                      </div>
                      <div className="col-2 text-center">
                        50%- ${fiftyPercent}
                      </div>
                      <div className="col-2 text-center">
                        Paid- ${paid}
                      </div>
                      <div className="col-4">
                        Due- ${due}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button className={`${style.createbtn}`} onClick={() => handleAccordionItemClick(item)}>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
          )
        })
      }


      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        dialogClassName={`${style.printmodal}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Accordion Data</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modalContent"
        >
          <div className="row">
            <style>
              {`
          @media print {
            .machinetable {
              
            }
            .maintablewrap {
              margin-top: 50px;
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
            .totalprofit{
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 10px;
            }
            .total{
              margin: 0px;
            }
            /* Add any other print-specific styles as needed */
          }
        `}
            </style>
            <div className={`col-12`}>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ padding: '10px' }}>Date</th>
                    <th style={{ padding: '10px' }}>Employee Name</th>
                    <th style={{ padding: '10px' }}>Location</th>
                    <th style={{ padding: '10px' }}>Machine</th>
                    <th style={{ padding: '10px' }}>Beginning</th>
                    <th style={{ padding: '10px' }}>Closed</th>
                    <th style={{ padding: '10px' }}>Location Address</th>
                  </tr>
                  <tr>
                    <th style={{ padding: '10px' }}>{new Date(selectedAccordionData?.location?.createdAt).toLocaleDateString()}</th>
                    <th style={{ padding: '10px' }}>{selectedAccordionData?.employee?.firstname} {selectedAccordionData?.employee?.lastname}</th>
                    <th style={{ padding: '10px' }}>{selectedAccordionData?.location?.locationname}</th>
                    <th style={{ padding: '10px' }}>{selectedAccordionData?.machines?.length}</th>
                    <th style={{ padding: '10px' }}>{selectedAccordionData?.machines?.length}</th>
                    <th style={{ padding: '10px' }}>0</th>
                    <th style={{ padding: '10px' }}>{selectedAccordionData?.location?.address}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map through your data and render each row */}
                  {/* {accordionData.map((item, index) => (
        // Render your data rows here
      ))} */}
                </tbody>
              </table>
            </div>
            <div className={`col-12 gap-5 d-flex flex-wrap maintablewrap`}>
              {
                selectedAccordionData?.machines?.map((machine, index) => {
                  return (
                    <div className='' key={index}>
                      <Table striped >
                        <thead>
                          <tr>
                            <th>{machine.machineNumber}-{machine.serialNumber}</th>
                            <th>Current</th>
                            <th>Previous</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>In</td>
                            <td>${machine.inNumbers.current}</td>
                            <td>${machine.inNumbers.previous}</td>
                            <td>${machine.inNumbers.current - machine.inNumbers.previous}</td>
                          </tr>
                          <tr>
                            <td>Out</td>
                            <td>${machine.outNumbers.current}</td>
                            <td>${machine.outNumbers.previous}</td>
                            <td>${machine.outNumbers.current - machine.outNumbers.previous}</td>
                          </tr>
                          <tr>
                            <td>Profit</td>
                            <td></td>
                            <td></td>
                            <td>${
                              (machine.inNumbers.current - machine.inNumbers.previous) -
                              (machine.outNumbers.current - machine.outNumbers.previous)
                            }</td>
                          </tr>
                        </tbody>
                      </Table>
                      <img src={machine.image[0]} alt='machin' width={'20%'} />
                    </div>
                  )
                })
              }
            </div>
            <div className='total'>
              <div className='d-flex totalprofit'>
                <h6>Total Profit:</h6>
                <h6>${totalProfitSelected}</h6>
              </div>
              <div className='d-flex totalprofit'>
                <h6>50%: </h6>
                <h6>${fiftyPercentSelected}</h6>
              </div>
              <div className='d-flex totalprofit'>
                <h6>Due: </h6>
                <h6>${dueSelected}</h6>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className={`${style.closebtn}`} onClick={handleModalClose}>
            Close
          </button>
          <button className={`${style.createbtn}`} onClick={() => { handlePrint(); handleModalClose(); }}>
            Print Report
          </button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-end">
        <div className='d-flex align-items-center me-3'>
          <div className='me-1'>
            Rows Per Page:
          </div>
          <div>
            <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
              {perPageOptions.map((option, idx) => (
                <option key={idx} value={option.value}>{option.label}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <ReactPaginate
          containerClassName={"pagination align-items-center mb-0"}
          activeClassName={`${style.pagination}`}
          pageClassName={"page-item p-2 px-3"}
          onPageChange={handlePageChange}
          breakLabel={<span className="break-label">...</span>}
          // pageCount={Math.ceil(locations?.length / n)}
          previousLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillLeftCircle />
            </IconContext.Provider>
          }
          nextLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillRightCircle />
            </IconContext.Provider>
          }
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
        />
      </div>
    </>
  )
}

export default CollectionReportList